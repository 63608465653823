.guarita-acesso {
    display: flex;
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 4px;
}

.guarita-acesso-ativo {
    min-width: 242px;
    flex: 1;
    padding: 4px;
}

.guarita-acesso-ativo div.guarita-acesso-pessoa {
    height: 100%;
}

.guarita-acesso-ativo div.guarita-acesso-pessoa div.guarita-acesso-pessoa-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.guarita-acesso-passivo {
    flex: 5;
    justify-content: center;
    padding: 2px 0;
    display: flex;
    flex-wrap: wrap;
}

.guarita-acesso-pessoa {
    text-align: center;
}

.guarita-acesso-pessoa.passivo {
    flex: 1;
    min-width: 212px;
    max-width: 12.5%;
    padding: 2px;
    box-sizing: border-box;
}

.guarita-acesso-pessoa.passivo div.guarita-acesso-pessoa-content img, .guarita-acesso-pessoa.passivo div.guarita-acesso-pessoa-content span {
    width: 96px;
    height: 96px;
}

.guarita-acesso-pessoa div.guarita-acesso-pessoa-content {
    user-select: none;
    background-color: #eee;
    height: 100%;
    border-radius: 4px;
    padding: 10px;
    white-space: nowrap;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    box-sizing: border-box;
}

.guarita-acesso-pessoa div.guarita-acesso-pessoa-content img, .guarita-acesso-pessoa div.guarita-acesso-pessoa-content span {
    width: 112px;
    height: 112px;
    border: 2px solid #ccc;
    border-radius: 4px;
}

.guarita-acesso-pessoa div.guarita-acesso-pessoa-content img.dentro, .guarita-acesso-pessoa div.guarita-acesso-pessoa-content span.dentro {
    border-color: #4caf50;
}

.guarita-acesso-pessoa div.guarita-acesso-pessoa-content img.fora, .guarita-acesso-pessoa div.guarita-acesso-pessoa-content span.fora {
    border-color: #f44336;
}

.guarita-acesso-pessoa div.guarita-acesso-pessoa-content.selecionado {
    background-color: #0c7cd5;
    color: #fff;
}

.guarita-acesso-actions {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid rgba(255, 255, 255, .2);
}

.guarita-acesso-etiquetas {
    max-width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@keyframes spin-animation {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.guarita-acesso-no-access {
    padding: 4em 2em;
    text-align: center;
    user-select: none;
}

.guarita-acesso-no-access span {
    position: relative;
    padding: 6px 14px 6px 36px;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 2em;
}

.guarita-acesso-no-access span::after {
    content: "\f110";
    font-family: "Font Awesome 5 Pro Light", sans-serif;
    position: absolute;
    top: 9px;
    left: 12px;
    animation: spin-animation 1.5s linear infinite;
}

.guarita-acesso-block {
    margin: 4px 5px !important;
    display: flex;
    height: 75px;
    padding: 0 !important;
}

@keyframes guarita-acesso-outro-fadein {
    0% {
        max-width: 0;
        filter: opacity(0);
    }
    25% {
        max-width: 12.5%;
    }
    100% {
        filter: opacity(1);
    }
}

.guarita-acesso-outro {
    animation: guarita-acesso-outro-fadein 0.3s linear;
    background-color: #fff;
    flex: 1;
    text-align: center;
    max-width: 12.5%;
    overflow: hidden;
    user-select: none;
}

.guarita-acesso-outro > div {
    margin: 0 2px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    padding: 4px 4px 6px 4px;
    background-color: #ffc107;
}

.guarita-acesso-outro > div.danger {
    background-color: #f44336;
    color: #fff;
}
