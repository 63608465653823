.form-container {
    width: 100%;
    margin: 1em;
    background: #FFFFFF;
    padding: 2em;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .4);
}

.form-title {
    text-align: center;
    font-weight: bold;
}

.form-body {

}

.form-body label {
    display: block;
    margin-top: 1em;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
}

.form-checkboxes {
    display: flex;
}

.form-footer {
    padding: 2em 0 0 0;
    text-align: right;
}

.form-question {
    padding: .5em 2em 0 0;
    text-align: right;
}

.form-answer div.ui-g-undefined {
    display: inline-block;
    margin: 0 2em 0 0;
}

.form-body div.ui-chkbox-box {
    width: 30px;
    height: 30px;
}

.form-body span.ui-chkbox-icon {
    line-height: 30px;
}

.form-signature {
    padding: 1em 0;
}