.action-container {
    display: inline-block;
    margin: 0 2px;
    height: 34px;
}

.action {
    line-height: 34px;
    display: inline-block;
    min-width: 140px !important;
    height: 34px;
    background-color: #2196f3;
    line-height: 20px;
    border: 1px solid #0960a5;
    border-bottom-width: 3px;
    border-radius: 3px;
    outline: 0;
    box-sizing: border-box !important;
    padding: 0 1em;
    cursor: pointer;
    color: #fff;
    font-family: "Titillium Web" !important;
}

.action:disabled {
    border-bottom-width: 1px;
    background-color: #ddd;
    border-color: #d0d0d0;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, .2);
    cursor: default;
}

.action:hover:not(:disabled) {
    background-color: #51adf6;
}

.action:active:not(:disabled) {
    background-color: #0c7cd5 !important;
    border-bottom-width: 1px;
    height: calc(34px - 2px);
    transform: translateY(2px);
}

.action.post:not(:disabled) {
    background-color: #4caf50;
    border-color: #2d682f;
}

.action.post:not(:disabled):hover {
    background-color: #6ec071 !important;
}

.action.post:not(:disabled):active {
    background-color: #3d8b40 !important;
}

.action.secondary:not(:disabled) {
    color: #666;
    background-color: #f8f8f8;
    border-color: #c5c5c5;
}

.action.secondary:not(:disabled):hover {
    background-color: #fff !important;
}

.action.secondary:not(:disabled):active {
    background-color: #dfdfdf !important;
}

.action.delete:not(:disabled) {
    color: #fff;
    background-color: #f44336;
    border-color: #ba160a;
}

.action.delete:not(:disabled):hover {
    background-color: #f77066 !important;
}

.action.delete:not(:disabled):active {
    background-color: #ea1c0d !important;
}

.action i {
    transform: translateY(1px);
    font-size: 16px;
    margin-right: 4px;
}

.action.icon-only {
    min-width: 0 !important;
}

.action.icon-only i {
    margin-right: 0;
}

.action-float {
    position: fixed;
    bottom: 16px;
    right: 14px;
    height: 54px;
}

.action-float-button {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    border: 1px solid #0960a5;
    background-color: #2196f3;
    color: #fff;
    margin: 0 2px;
    cursor: pointer;
    padding: 0;
    text-align: center;
    outline: 0;
    box-sizing: content-box !important;
    line-height: 52px;
    border-bottom-width: 3px;
}

.action-float-button.small {
    width: 30px !important;
    height: 30px !important;
    line-height: 32px;
}

.action-float-button.small i {
    font-size: 16px;
}

.action-float-button i {
    font-size: 20px;
}

.action-float-button:hover:not(:disabled) {
    background-color: #51adf6;
}

.action-float-button:active {
    background-color: #0c7cd5 !important;
    border-bottom-width: 1px;
    transform: translateY(2px);
}

.action-float-button.post:not(:disabled) {
    background-color: #4caf50;
    border-color: #2d682f;
}

.action-float-button.post:not(:disabled):hover {
    background-color: #6ec071 !important;
}

.action-float-button.post:not(:disabled):active {
    background-color: #3d8b40 !important;
}

.action-float-button.secondary:not(:disabled) {
    color: #666;
    background-color: #f8f8f8;
    border-color: #c5c5c5;
}

.action-float-button.secondary:not(:disabled):hover {
    background-color: #fff !important;
}

.action-float-button.secondary:not(:disabled):active {
    background-color: #dfdfdf !important;
}

.action-float-button.delete:not(:disabled) {
    color: #fff;
    background-color: #f44336;
    border-color: #ba160a;
}

.action-float-button.delete:not(:disabled):hover {
    background-color: #f77066 !important;
}

.action-float-button.delete:not(:disabled):active {
    background-color: #ea1c0d !important;
}

.ui-iconpanel {
    display: flex;
    flex-wrap: wrap;
}

.ui-iconpanel-item {
    flex: 1;
    margin: 2px;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 2px;
    text-align: center;
    height: 34px;
    min-width: 34px;
    line-height: 30px !important;
    cursor: pointer;
    font-size: 16px;
    display: inline-block;
}

.ui-iconpanel-item.selected {
    color: #fff;
    background-color: #2196f3;
    border-color: #0960a5;
}

.starrating-container {
    text-align: center;
}

.starrating-container i {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    cursor: pointer;
    font-size: 24px;
    color: #ccc;
}

.starrating-container i.active {
    color: #ffc107;
}
