.monitor-presenca-container {
    display: flex;
    position: fixed;
    top: 50px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 80px);
    flex-wrap: wrap;
    flex-direction: column;
    user-select: none;
    min-width: 1280px;
    min-height: 720px;
    overflow-y: hidden;
}

.monitor-presenca-header {
    display: flex;
    flex: 1;
}

.monitor-presenca-semaforo {
    flex: 3;
    display: flex;
    flex-direction: row;
    margin: 0 5px 5px 0;
}

.monitor-presenca-unidades {
    flex: 1;
    margin: 0 0 5px 5px;
}

.monitor-presenca-lpus-container {
    display: flex;
    flex-wrap: wrap;
}

.monitor-presenca-lpus-container div {
    flex: 1;
    min-width: 100%;
    margin-bottom: 2px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .1);
}

.monitor-presenca-lpus-container h1 {
    float: left;
    width: 70%;
    margin: 0;
    padding: 4px 10px;
    text-align: center;
}

.monitor-presenca-lpus-container h2 {
    float: right;
    width: 30%;
    margin: 0;
    padding: 4px 10px;
    text-align: center;
}

.monitor-presenca-atrasos {
    min-width: 100%;
    max-height: 87.5%;
    margin-top: 5px;
    flex: 7;
    padding: 10px 0 0 10px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .2);
}

.monitor-presenca-semaforo-item {
    position: relative;
    flex: 1;
    background-color: #EEEEEE;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .2);
    font-size: 36px;
    padding: .25em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
}

.monitor-presenca-semaforo-item:first-child {
    margin-left: 0;
}

.monitor-presenca-semaforo-item:last-child {
    margin-right: 0;
}

.monitor-presenca-semaforo-item.green {
    background-color: #4CAF50;
    color: #FFFFFF;
}

.monitor-presenca-semaforo-item.green::before {
    content: "Regular";
    position: absolute;
    top: 4px;
    left: 10px;
    color: #FFFFFF;
    font-size: 14px;
}

.monitor-presenca-semaforo-item.yellow {
    background-color: #FFC107;
}

.monitor-presenca-semaforo-item.yellow::before {
    content: "Atenção";
    position: absolute;
    top: 4px;
    left: 10px;
    font-size: 14px;
}

.monitor-presenca-semaforo-item.red {
    background-color: #F44336;
    color: #FFFFFF;
}

.monitor-presenca-semaforo-item.red::before {
    content: "Atraso";
    position: absolute;
    top: 4px;
    left: 10px;
    color: #FFFFFF;
    font-size: 14px;
}

.monitor-presenca-atrasos-item {
    width: 20%;
    height: 12.5%;
    min-width: 250px;
    max-width: 20%;
    min-height: calc(12.5% - 10px);
    float: left;
    white-space: nowrap;
    overflow-x: hidden;
    box-sizing: border-box;
}

.monitor-presenca-atrasos-item > div {
    background-color: #EEEEEE;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
}

.monitor-presenca-atrasos-item > div > img {
    display: inline-block;
    width: calc(23%);
    height: calc(100% - 12px);
    box-sizing: border-box;
    margin: 6px;
    float: left;
    border-radius: 6px;
    vertical-align: middle;
    border: 1px solid rgba(0, 0, 0, .05);
}

.monitor-presenca-atrasos-text {
    height: calc(100% - 4px);
    flex-direction: column;
    display: flex;
    justify-content: center;
}

.monitor-presenca-atrasos-item.monitor {
    height: 24.999%;
    min-height: calc(25% - 10px);
    position: relative;
}

.monitor-presenca-atrasos-item.monitor > div {
    background-color: #0C7CD5;
    color: #FFFFFF;
    position: relative;
}

.monitor-presenca-atrasos-item.monitor > div::before {
    content: "Responsável";
    position: absolute;
    top: 4px;
    left: 10px;
}

.monitor-presenca-atrasos-item.monitor > div > img {
    height: calc(50% - 12px);
    margin-top: calc(12.5% + 6px);
}

.monitor-presenca-counter {
    display: inline-block;
    position: absolute;
    top: 4px;
    right: 14px;
    width: 16px;
    height: 16px;
    background-color: rgba(255, 255, 255, .2);
    border-radius: 2px;
    color: #FFFFFF;
    font-size: 12px;
    text-align: center;
    line-height: 15px;
}

.monitor-presenca-page-counter {
    top: 4px;
    position: absolute;
    right: 34px;
    font-size: 11px;
    color: #FFFFFF;
    padding: 0 4px;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, .2);
}