.password-scorer-container {
    display: flex;
    flex-direction: row;
    gap: 0 2px;
    margin-top: -4px;
}

.password-scorer {
    flex: 1;
    height: 4px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, .2);
    position: relative;
}

.password-scorer.score-1 {
    background-color: #F44336;
}

.password-scorer.score-2 {
    background-color: #FF9800;
}

.password-scorer.score-3 {
    background-color: #FFC107;
}

.password-scorer.score-4 {
    background-color: #CDDC39;
}

.password-scorer.score-5 {
    background-color: #4CAF50;
}
