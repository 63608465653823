.placa-mercosul {
  border: 2px solid #888888;
  border-radius: 5px;
  width: 90px;
  position: relative;
  display: inline-block;
  height: 40px;
}

.placa-mercosul-content {
  width: 100%;
  border-top: 10px solid rgb(8, 60, 198);
  border-radius: 3px;
  padding: 2px 6px;
  font-size: 18px !important;
  line-height: 22px;
  letter-spacing: 1px;
  background-color: #EEEEEE;
  font-family: "Euro Plate" !important;
  text-align: center !important;
}

.placa-mercosul::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  background: repeating-linear-gradient(-45deg, rgba(238, 238, 238, .1), rgba(238, 238, 238, .1) 1px, transparent 1px, transparent 3px);
}

.placa-label-pais {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 6px !important;
  font-family: "Euro Plate" !important;
  color: #FFFFFF;
  margin-top: 1px;
  letter-spacing: 1px;
}

.placa-screw {
  width: 3px;
  height: 3px;
  position: absolute;
  top: 3px;
  left: 12px;
  border-radius: 50%;
  background-color: #FFFFFF;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .3);
}

.placa-screw.right {
  left: 72px;
}
