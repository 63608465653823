@import url(./media/fonts/fontawesome/css/fontawesome.min.css);
@import url(./media/fonts/fontawesome/css/light.min.css);
@import url(./media/fonts/fontawesome/css/duotone.min.css);


@font-face {
  src: url("./media/fonts/fontawesome/webfonts/fa-light-300.ttf");
  font-family: "Font Awesome 5 Pro Light";
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: rgb(232, 232, 232);
}

* {
  font-family: "Titillium Web";
}

.fa {
  font-family: "Font Awesome 5 Pro Light" !important;
}
