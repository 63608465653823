.facial-dialog {

}

.facial-dialog *.result-status {
  color: #FFFFFF;
  background-color: #546E7A;
  padding: 4px 10px;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 3px;
  margin: 2px 0;
  display: flex;
  line-height: 1em;
}

.facial-dialog *.result-status i {
  padding: 4px 10px;
}

.facial-dialog *.result-status span {
  display: inline-block;
  width: 36px;
  text-align: center;
  margin-right: 10px;
  color: #546E7A;
  border: 1px solid rgba(255, 255, 255, .5);
  border-radius: 3px;
  background-color: #FFFFFF;
}

.facial-dialog *.result-status.success span {
  color: #4CAF50;
}

.facial-dialog *.result-status.failure span {
  color: #F44336;
}

.facial-dialog *.result-status.success {
  background-color: #4CAF50;
}

.facial-dialog *.result-status.failure {
  background-color: #F44336;
}

.facial-dialog img.mini-photo {
  width: 50px;
  height: 50px;
  border-radius: 3px;
}

.facial-dialog *.panel-pessoa {
  display: flex;
  flex-direction: row;
  gap: .5em;
  margin: .4em .5em 0 .3em;
}