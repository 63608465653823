.cssload-tetrominos {
	position: fixed;
  left: calc(50% - 60px);
  top: calc(50% - 55px);
	transform: scale(.5, .5);
}

.cssload-tetrominos-text {
  position: fixed;
  text-align: center;
	width: 100%;
	font-size: 13px;
  top: calc(50% + 40px);
}

.cssload-tetromino {
	width: 94px;
	height: 109px;
	position: fixed;
	transition: all ease 0.35s;
		-o-transition: all ease 0.35s;
		-ms-transition: all ease 0.35s;
		-webkit-transition: all ease 0.35s;
		-moz-transition: all ease 0.35s;
	background: url('data:image/svg+xml;utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 684"%3E%3Cpath fill="%23010101" d="M305.7 0L0 170.9v342.3L305.7 684 612 513.2V170.9L305.7 0z"/%3E%3Cpath fill="%23fff" d="M305.7 80.1l-233.6 131 233.6 131 234.2-131-234.2-131"/%3E%3C/svg%3E') no-repeat top center;
}

.cssload-box1 {
	animation: cssload-tetromino1 1.73s ease-out infinite;
		-o-animation: cssload-tetromino1 1.73s ease-out infinite;
		-ms-animation: cssload-tetromino1 1.73s ease-out infinite;
		-webkit-animation: cssload-tetromino1 1.73s ease-out infinite;
		-moz-animation: cssload-tetromino1 1.73s ease-out infinite;
}

.cssload-box2 {
	animation: cssload-tetromino2 1.73s ease-out infinite;
		-o-animation: cssload-tetromino2 1.73s ease-out infinite;
		-ms-animation: cssload-tetromino2 1.73s ease-out infinite;
		-webkit-animation: cssload-tetromino2 1.73s ease-out infinite;
    -moz-animation: cssload-tetromino2 1.73s ease-out infinite;
    background: url('data:image/svg+xml;utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 684"%3E%3Cpath fill="%23D80000" d="M305.7 0L0 170.9v342.3L305.7 684 612 513.2V170.9L305.7 0z"/%3E%3Cpath fill="%23fff" d="M305.7 80.1l-233.6 131 233.6 131 234.2-131-234.2-131"/%3E%3C/svg%3E') no-repeat top center;
}

.cssload-box3 {
	animation: cssload-tetromino3 1.73s ease-out infinite;
		-o-animation: cssload-tetromino3 1.73s ease-out infinite;
		-ms-animation: cssload-tetromino3 1.73s ease-out infinite;
		-webkit-animation: cssload-tetromino3 1.73s ease-out infinite;
		-moz-animation: cssload-tetromino3 1.73s ease-out infinite;
	z-index: 2;
}

.cssload-box4 {
	animation: cssload-tetromino4 1.73s ease-out infinite;
		-o-animation: cssload-tetromino4 1.73s ease-out infinite;
		-ms-animation: cssload-tetromino4 1.73s ease-out infinite;
		-webkit-animation: cssload-tetromino4 1.73s ease-out infinite;
		-moz-animation: cssload-tetromino4 1.73s ease-out infinite;
}

@keyframes cssload-tetromino1 {
	0%, 40% {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(47px, -26px);
	}
	60%, 100% {
		transform: translate(94px, 0);
	}
}

@-o-keyframes cssload-tetromino1 {
	0%, 40% {
		-o-transform: translate(0, 0);
	}
	50% {
		-o-transform: translate(47px, -26px);
	}
	60%, 100% {
		-o-transform: translate(94px, 0);
	}
}

@-ms-keyframes cssload-tetromino1 {
	0%, 40% {
		-ms-transform: translate(0, 0);
	}
	50% {
		-ms-transform: translate(47px, -26px);
	}
	60%, 100% {
		-ms-transform: translate(94px, 0);
	}
}

@-webkit-keyframes cssload-tetromino1 {
	0%, 40% {
		-webkit-transform: translate(0, 0);
	}
	50% {
		-webkit-transform: translate(47px, -26px);
	}
	60%, 100% {
		-webkit-transform: translate(94px, 0);
	}
}

@-moz-keyframes cssload-tetromino1 {
	0%, 40% {
		-moz-transform: translate(0, 0);
	}
	50% {
		-moz-transform: translate(47px, -26px);
	}
	60%, 100% {
		-moz-transform: translate(94px, 0);
	}
}

@keyframes cssload-tetromino2 {
	0%, 20% {
		transform: translate(94px, 0px);
	}
	40%, 100% {
		transform: translate(140px, 26px);
	}
}

@-o-keyframes cssload-tetromino2 {
	0%, 20% {
		-o-transform: translate(94px, 0px);
	}
	40%, 100% {
		-o-transform: translate(140px, 26px);
	}
}

@-ms-keyframes cssload-tetromino2 {
	0%, 20% {
		-ms-transform: translate(94px, 0px);
	}
	40%, 100% {
		-ms-transform: translate(140px, 26px);
	}
}

@-webkit-keyframes cssload-tetromino2 {
	0%, 20% {
		-webkit-transform: translate(94px, 0px);
	}
	40%, 100% {
		-webkit-transform: translate(140px, 26px);
	}
}

@-moz-keyframes cssload-tetromino2 {
	0%, 20% {
		-moz-transform: translate(94px, 0px);
	}
	40%, 100% {
		-moz-transform: translate(140px, 26px);
	}
}

@keyframes cssload-tetromino3 {
	0% {
		transform: translate(140px, 26px);
	}
	20%, 60% {
		transform: translate(94px, 53px);
	}
	90%, 100% {
		transform: translate(47px, 26px);
	}
}

@-o-keyframes cssload-tetromino3 {
	0% {
		-o-transform: translate(140px, 26px);
	}
	20%, 60% {
		-o-transform: translate(94px, 53px);
	}
	90%, 100% {
		-o-transform: translate(47px, 26px);
	}
}

@-ms-keyframes cssload-tetromino3 {
	0% {
		-ms-transform: translate(140px, 26px);
	}
	20%, 60% {
		-ms-transform: translate(94px, 53px);
	}
	90%, 100% {
		-ms-transform: translate(47px, 26px);
	}
}

@-webkit-keyframes cssload-tetromino3 {
	0% {
		-webkit-transform: translate(140px, 26px);
	}
	20%, 60% {
		-webkit-transform: translate(94px, 53px);
	}
	90%, 100% {
		-webkit-transform: translate(47px, 26px);
	}
}

@-moz-keyframes cssload-tetromino3 {
	0% {
		-moz-transform: translate(140px, 26px);
	}
	20%, 60% {
		-moz-transform: translate(94px, 53px);
	}
	90%, 100% {
		-moz-transform: translate(47px, 26px);
	}
}

@keyframes cssload-tetromino4 {
	0%, 60% {
		transform: translate(47px, 26px);
	}
	90%, 100% {
		transform: translate(0, 0);
	}
}

@-o-keyframes cssload-tetromino4 {
	0%, 60% {
		-o-transform: translate(47px, 26px);
	}
	90%, 100% {
		-o-transform: translate(0, 0);
	}
}

@-ms-keyframes cssload-tetromino4 {
	0%, 60% {
		-ms-transform: translate(47px, 26px);
	}
	90%, 100% {
		-ms-transform: translate(0, 0);
	}
}

@-webkit-keyframes cssload-tetromino4 {
	0%, 60% {
		-webkit-transform: translate(47px, 26px);
	}
	90%, 100% {
		-webkit-transform: translate(0, 0);
	}
}

@-moz-keyframes cssload-tetromino4 {
	0%, 60% {
		-moz-transform: translate(47px, 26px);
	}
	90%, 100% {
		-moz-transform: translate(0, 0);
	}
}
