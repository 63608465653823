@keyframes tzm-monitor-item-animation {
    0% {
        height: 0;
        background-color: #4CAF50;
        color: #FFFFFF;
    }
    5% {
        height: 100px;
    }
    6% {
        height: auto;
    }
    90% {
        background-color: #4CAF50;
        color: #FFFFFF;
    }
    100% {
        background-color: #FFFFFF;
        color: #000000;
    }
}

.tzm-monitor-item {
    animation: tzm-monitor-item-animation 10s forwards;
    border: 1px solid #CCCCCC;
    margin: 2px 0;
    border-radius: 3px;
    padding: 4px 10px 4px 4px;
    white-space: nowrap;
    overflow: hidden;
    background-color: #FFFFFF;
    min-height: 116px;
}

.tzm-monitor-item-foto img {
    width: 96px;
    height: 96px;
    float: left;
    margin-right: 10px;
    border: 1px solid #CCCCCC;
    border-radius: 2px;
}
