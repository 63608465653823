@font-face {
    font-family: "weathericons";
    src: url(../../media/fonts/weather_icons.ttf);
}

.ui-weather {
	font-weight: bold;
}

.wi {
	margin: 1vw;
}

.ui-weather-days {
	display: inline-block;
	width: calc(20%);
	text-align: center;
	align-items: center;
	box-sizing: border-box;
	height: 100%;
}

.ui-weather-days-closure {
	position: absolute;
	width: calc(100% - 4vw);
	box-sizing: border-box;
	height: calc(45% - 2vw);
	margin: 2vw;
	bottom: 0;
	font-size: 5vw;
	line-height: 9vh;
	padding-top: 2.5vh;
}

.ui-weather-day {
	position: absolute;
	text-align: center;
	top: 0;
	left: 0;
	width: calc(100% - 4vw);
	box-sizing: border-box;
	height: calc(55% - 4vw);
	margin: 2vw;
	font-size: 8vw;
	line-height: 14vh;
	padding-top: 1vh;
}
