.grafico-toggle-period {
    position: absolute;
    top: -2px;
    right: 0;
}

.grafico-toggle-period button {
    font-size: 11px;
    opacity: .5;
    margin-right: 2px;
    min-width: 50px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, .3);
}

.grafico-toggle-period button:hover {
    opacity: 1;
}

.table-sumario {
    position: relative;
    float: right;
    width: 31%;
    height: 100%;
}

.table-sumario > div {
    height: 95%;
    overflow-y: scroll;
}

.table-sumario > div > div.ui-datatable-tablewrapper {
    height: calc(100% - 29px);
}

.table-sumario table {
    height: 100%;
}

.table-sumario * {
    font-size: 11px !important;
}

.table-sumario-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .7);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

@keyframes table-sumario-loading-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.table-sumario-loading::before {
    flex: 1;
    content: "\f110";
    font-family: "Font Awesome 5 Pro";
    font-size: 16px !important;
    color: #000000;
    animation: table-sumario-loading-spin 1s linear infinite;
}
