.input-senha {
	width: 50px;
	height: 70px;
	vertical-align: top;
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: 4px;
	margin: 0 4px;
	display: inline-block;
	border-bottom-width: 4px;
	text-shadow: -1px -1px 1px rgba(0, 0, 0, .1);
}

.input-senha span {
	text-align: center;
	line-height: 64px !important;
	font-size: 32px !important;
}

.input-senha i {
	text-align: center;
	line-height: 64px !important;
	font-size: 20px !important;
}

.input-senha.complete {
	background-color: rgba(0, 0, 0, .05);
}

.cartao-wiegand {
	height: 156px;
	position: relative;
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: 10px;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, .3);
}

.cartao-wiegand div.decimal {
	position: absolute;
	bottom: 10px;
	font-size: 16px;
	font-style: italic;
	right: 16px;
}

.cartao-wiegand div.legenda {
	position: absolute;
	bottom: 26px;
	right: 14px;
}

.cadastro-controle {
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: 3px;
	background-color: #222222;
	color: #FFFFFF;
	padding: 2em !important;
	margin: .5em;
	text-align: center;
}

.cadastro-controle.ok {
	background-color: #3D8B40;
}
