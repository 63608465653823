.tablet-ativo-unidades {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 .5em 4.5em .5em;
}

.tablet-ativo-bloco {
    display: flex;
    padding: 2px 2px 2px 32px;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, .1);
    border-radius: 3px;
    margin: 2px;
    position: relative;
}

.tablet-ativo-unidade {
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 3px;
    text-align: center;
    background-color: #FFFFFF;
    cursor: pointer;
    font-size: 13px;
    line-height: 50px;
    box-sizing: border-box;
    min-width: 50px;
    min-height: 50px;
    justify-content: center;
    align-items: center;
    align-content: center;
    user-select: none;
    margin: 1px;
}

.tablet-ativo-bloco-descricao {
    padding: 2px 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 18px;
    text-align: center;
    margin: 4px;
    color: #FFFFFF;
    user-select: none;
    background-color: #607D8B;
    border-radius: 3px;
}

.tablet-ativo-unidade.selecionada {
    background-color: #2196F3;
    color: #FFFFFF;
    position: relative;
}

.tablet-ativo-unidade.selecionada::after {
    content: "\f00C";
    font-family: "Font Awesome 5 Pro";
    color: #FFFFFF;
    text-shadow: 0 0 2px #000000;
    position: absolute;
    bottom: -14px !important;
    right: 4px !important;
    font-size: 20px;
}

.tablet-ativo-unidade span {
    display: inline-block;
}

.tablet-ativo-acoes {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    display: flex;
    background-color: #222222;
}

.tablet-ativo-acoes div {
    color: #FFFFFF;
    text-align: center;
    min-height: 50px;
    min-width: 50px;
    font-size: 24px;
    line-height: 50px;
    border: 1px solid rgba(255, 255, 255, .5);
    margin: 4px 2px;
    border-radius: 3px;
}

.tablet-ativo-acoes div:active {
    background-color: #444444;
}

.tablet-ativo-acoes div.entrada {
    background-color: #2E7D32;
}

.tablet-ativo-acoes div.entrada:active {
    background-color: #66BB6A;
}

.tablet-ativo-acoes div.saida {
    background-color: #C62828;
}

.tablet-ativo-acoes div.saida:active {
    background-color: #EF5350;
}

.rotate-45 {
    transform: rotate(45deg);
}

.tablet-ativo-veiculo {
    background-color: #FFFFFF;
    margin: .75em;
    padding: .5em;
    border-radius: 3px;
    font-size: 13px;
    color: #000000;
}

.tablet-ativo-pessoa {
    padding: .25em;
    line-height: 13px;
    user-select: none;
}

.tablet-ativo-pessoa-selecionada {
    padding: .25em;
    border: 1px solid rgba(0, 0, 0, .2);
    display: inline-block;
    margin: .25em;
    min-width: calc(202px + .5em);
    max-width: calc(202px + .5em);
    text-align: center;
    position: relative;
}

.tablet-ativo-pessoa-selecionada img.foto {
    width: 100px;
    height: 100px;
}

.tablet-ativo-pessoa-selecionada img.remover {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 4px;
    right: 4px;
}

.tablet-ativo-cadastro {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 76px);
    overflow-y: scroll;
    background-color: #FFFFFF;
    padding-bottom: 1em;
}

.tablet-ativo-webcam {
    height: 480px;
    text-align: center;
    padding: 0;
    border-radius: 3px;
}

.tablet-ativo-webcam img {
    width: 480px;
    height: 480px;
    display: inline-block;
}

.tablet-ativo-cadastro-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
}

.tablet-ativo-position-dot {
    position: absolute;
    top: calc(100px - 16px);
    right: calc(50px + .5em);
    width: 16px;
    height: 16px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
}

.tablet-button {
    height: 42px !important;
}

.tablet-logout {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px !important;
    background-color: #F44336;
    color: #FFFFFF;
    border-radius: 3px;
}

.tablet-logout:active {
    background-color: #D32F2F;
}

.tablet-mainbar {
    height: 50px;
    line-height: 60px;
    font-size: 13px;
    text-align: right;
    padding-right: 60px;
}
