.botao-dedo {
    cursor: pointer;
    width: 24px;
    height: 40px;
    border-radius: 12px;
    position: absolute;
    background-color: #CCCCCC;
    top: 0;
    left: 0;
}

.botao-dedo:hover {
    background-color: #DDDDDD;
}

.botao-dedo:active {
    background-color: #666666;
}

.botao-dedo.d0 {
    top: 153px;
    left: 56px;
}

.botao-dedo.d1 {
    top: 44px;
    left: 98px;
    width: 25px;
}

.botao-dedo.d2 {
    top: 30px;
    left: 141px;
    width: 25px;
}

.botao-dedo.d3 {
    top: 44px;
    left: 184px;
    width: 25px;
}

.botao-dedo.d4 {
    top: 90px;
    left: 227px;
    width: 25px;
}

.botao-dedo.d5 {
    top: 90px;
    left: 294px;
    width: 25px;
}

.botao-dedo.d6 {
    top: 44px;
    left: 337px;
    width: 25px;
}

.botao-dedo.d7 {
    top: 30px;
    left: 380px;
    width: 25px;
}

.botao-dedo.d8 {
    top: 44px;
    left: 423px;
    width: 25px;
}

.botao-dedo.d9 {
    top: 153px;
    left: 466px;
}

.botao-dedo.active {
    background-color: #0C7CD5;
}

.botao-dedo.exists {
    background-color: #F44336;
}

.botao-dedo.success {
    background-color: #4CAF50;
}

.botao-dedo-mensagem {
    height: 36px;
    line-height: 36px;
    width: 100%;
    background-color: #EEEEEE;
    border-radius: 3px;
}

.botao-dedo-mensagem.SUCESSO {
    background-color: #4CAF50;
    color: #FFFFFF;
}

.botao-dedo-mensagem.PENDENTE {
    background-color: #FF9800;
    color: #FFFFFF;
}

.botao-dedo-mensagem.FALHA {
    background-color: #F44336;
    color: #FFFFFF;
}

.hand-name {
    position: absolute;
    top: 280px;
    left: 86px;
    background-color: #0C7CD5;
    color: #FFFFFF;
    padding: .5em 1em;
    border-radius: 3px;
    width: 130px;
}

.hand-name.right {
    left: 330px;
}