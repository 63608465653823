.modo-emergencia {
	background: repeating-linear-gradient(45deg, #ffe7a0, #ffe7a0 10px, #b7c6cd 10px, #b7c6cd 20px);
	border-right: 1px solid #b7c6cd;
	padding: 0.71em;
	float: left;
	margin: -0.7em 0 0 -0.7em;
	border-bottom-left-radius: 2px;
}

.modo-emergencia.active {
	background: repeating-linear-gradient(45deg, #ffc107, #ffc107 10px, #607d8b 10px, #607d8b 20px);
	border-right: 1px solid #607d8b;
}
