.tzm-failed-fetch {
  position: fixed;
  user-select: none;
  top: 12px;
  padding: 2em;
  right: 12px;
  background-color: #F44336;
  color: #FFFFFF;
  font-size: 14px;
  z-index: 10000000;
  border-radius: 1em;
  display: flex;
  align-items: center;
}

.tzm-failed-fetch.hide {
  transition: transform .2s ease-in-out, background-color .2s ease-in-out, color .2s ease-in-out, opacity .2s ease-in-out;
  transform: scaleY(0);
  background-color: #FFFFFF;
  color: #000000;
  transform-origin: 0 0;
  opacity: 0;
}
